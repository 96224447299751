@charset "UTF-8";
body {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 400; }

h1,
h2 {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 700; }

h3,
h4,
h5 {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 500;
  color: #CD4945; }

a {
  font-family: "Noto Sans KR", "맑은 고딕", "애플 SD 산돌고딕 Neo", "Apple SD Gothic Neo", "Roboto", sans-serif;
  font-weight: 400;
  color: #CD4945; }

a:hover {
  color: #F77975; }

a:active {
  color: #933432; }

.alternative-font {
  color: #CD4945; }

p.drop-caps:first-child:first-letter {
  color: #CD4945; }

p.drop-caps.secundary:first-child:first-letter {
  background-color: #CD4945; }

ul.nav-pills > li.active > a {
  background-color: #CD4945; }

ul.nav-pills > li.active > a:hover,
ul.nav-pills > li.active > a:focus {
  background-color: #CD4945; }

.sort-source-wrapper .nav > li.active > a {
  color: #CD4945; }

.sort-source-wrapper .nav > li.active > a:hover,
.sort-source-wrapper .nav > li.active > a:focus {
  color: #CD4945; }

/* Labels */
.label-primary {
  background-color: #CD4945; }

.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #CD4945; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #CD4945;
  border-color: #CD4945; }

body.dark .pagination > li > a,
body.dark .pagination > li > span,
body.dark .pagination > li > a:hover,
body.dark .pagination > li > span:hover,
body.dark .pagination > li > a:focus,
body.dark .pagination > li > span:focus {
  color: #CD4945; }

body.dark .pagination > .active > a,
body.dark .pagination > .active > span,
body.dark .pagination > .active > a:hover,
body.dark .pagination > .active > span:hover,
body.dark .pagination > .active > a:focus,
body.dark .pagination > .active > span:focus {
  background-color: #CD4945;
  border-color: #CD4945; }

.btn-primary,
.pagination > .active > a,
body.dark .pagination > .active > a,
.owl-theme .owl-controls .owl-nav [class*="owl-"] {
  border-color: #CD4945 !important;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #CD4945;
  border-color: #CD4945; }

.btn-primary:hover,
.pagination > .active > a:hover,
body.dark .pagination > .active > a:hover,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:hover {
  border-color: #F77975;
  background-color: #F77975; }

.btn-primary:active,
.pagination > .active > a:active,
body.dark .pagination > .active > a:active,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:active,
.btn-primary:focus,
.pagination > .active > a:focus,
body.dark .pagination > .active > a:focus,
.owl-theme .owl-controls .owl-nav [class*="owl-"]:focus {
  border-color: #933432;
  background-color: #933432; }

.progress-bar-primary {
  background-color: #CD4945; }

.btn-success {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #47a447;
  border-color: #47a447; }

.btn-success:hover {
  border-color: #51b451;
  background-color: #51b451; }

.btn-success:active,
.btn-success:focus {
  border-color: #3f923f;
  background-color: #3f923f; }

.btn-warning {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #ed9c28;
  border-color: #ed9c28; }

.btn-warning:hover {
  border-color: #efa740;
  background-color: #efa740; }

.btn-warning:active,
.btn-warning:focus {
  border-color: #e89113;
  background-color: #e89113; }

.btn-danger {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #d2322d;
  border-color: #d2322d; }

.btn-danger:hover {
  border-color: #d64742;
  background-color: #d64742; }

.btn-danger:active,
.btn-danger:focus {
  border-color: #bd2d29;
  background-color: #bd2d29; }

.btn-info {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #5bc0de;
  border-color: #5bc0de; }

.btn-info:hover {
  border-color: #70c8e2;
  background-color: #70c8e2; }

.btn-info:active,
.btn-info:focus {
  border-color: #46b8da;
  background-color: #46b8da; }

.btn-dark {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #171717;
  border-color: #171717; }

.btn-dark:hover {
  border-color: #242424;
  background-color: #242424; }

.btn-dark:active,
.btn-dark:focus {
  border-color: #0a0a0a;
  background-color: #0a0a0a; }

section.highlight {
  background-color: #CD4945 !important;
  border-color: #933432 !important; }

section.page-top h1 {
  border-bottom-color: #CD4945; }

section.toggle label {
  color: #CD4945;
  border-left-color: #CD4945;
  border-right-color: #CD4945; }

section.toggle.active > label {
  background-color: #CD4945;
  border-color: #CD4945;
  color: #FFF; }

section.page-top.custom-product {
  background-color: #933432;
  border-top-color: #0081c2; }

.feature-box .feature-box-icon {
  background-color: #CD4945; }

.feature-box.secundary .feature-box-icon i.fa {
  color: #CD4945; }

.thumb-info .thumb-info-type {
  background-color: #CD4945; }

.thumb-info .thumb-info-action-icon {
  background-color: #CD4945; }

.thumb-info-social-icons a {
  background-color: #CD4945; }

.thumbnail .zoom {
  background-color: #CD4945; }

.img-thumbnail .zoom {
  background-color: #CD4945; }

.inverted {
  background-color: #CD4945; }

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #0074ad; }

.tabs ul.nav-tabs a,
.tabs ul.nav-tabs a:hover {
  color: #CD4945; }

.tabs ul.nav-tabs a:hover,
.tabs ul.nav-tabs a:focus {
  border-top-color: #CD4945; }

.tabs ul.nav-tabs li.active a {
  border-top-color: #CD4945;
  color: #CD4945; }

.tabs ul.nav-tabs.nav-justified a:hover,
.tabs ul.nav-tabs.nav-justified a:focus {
  border-top-color: #CD4945; }

.tabs.tabs-bottom ul.nav-tabs li a:hover,
.tabs.tabs-bottom ul.nav-tabs li.active a,
.tabs.tabs-bottom ul.nav-tabs li.active a:hover,
.tabs.tabs-bottom ul.nav-tabs li.active a:focus {
  border-bottom-color: #CD4945; }

.tabs.tabs-vertical.tabs-left ul.nav-tabs li a:hover,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:hover,
.tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:focus {
  border-left-color: #CD4945; }

.tabs.tabs-vertical.tabs-right ul.nav-tabs li a:hover,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:hover,
.tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:focus {
  border-right-color: #CD4945; }

ul.list.icons li i {
  color: #CD4945; }

i.icon-featured {
  background-color: #CD4945; }

.parallax blockquote i.fa-quote-left {
  color: #CD4945; }

section.video blockquote i.fa-quote-left {
  color: #CD4945; }

.panel-group .panel-heading a {
  color: #CD4945; }

.panel-group.secundary .panel-heading {
  background-color: #CD4945 !important; }

header {
  background: #ffffff;
  clear: both;
  border-top: 5px solid #ededed;
  padding: 8px 0; }

header nav ul.nav-main li a {
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-left: 3px;
  margin-right: 3px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 13px; }

header nav ul.nav-main ul.dropdown-menu,
header nav ul.nav-main li.dropdown.open .dropdown-toggle,
header nav ul.nav-main li.active a,
header nav ul.nav-main li > a:hover,
header nav ul.nav-main li.dropdown:hover a {
  color: #FFF;
  background-color: #CD4945 !important; }

header nav ul.nav-main ul.dropdown-menu li:hover > a {
  background-color: #00aaff !important; }

header nav ul.nav-main ul.dropdown-menu {
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #CD4945;
  border-color: #CD4945; }

header nav ul.nav-main ul.dropdown-menu:hover {
  border-color: #F77975;
  background-color: #F77975; }

header nav ul.nav-main ul.dropdown-menu:active,
header nav ul.nav-main ul.dropdown-menu:focus {
  border-color: #933432;
  background-color: #933432; }

header nav ul.nav-main li.dropdown:hover ul.dropdown-menu li > a {
  background: none !important; }

header nav ul.nav-main li.dropdown:hover ul.dropdown-menu li > a:hover {
  background: #F77975 !important; }

@media (min-width: 992px) {
  #header.single-menu nav ul.nav-main li.active > a {
    border-top: 5px solid #CD4945; }
  #header.darken-top-border {
    border-top: 5px solid #933432 !important; }
  #header.colored .header-top {
    background-color: #CD4945; }
  #header.colored .header-top ul.nav-top li a:hover {
    background-color: #F77975 !important; }
  #header.flat-menu nav ul.nav-main li > a,
  #header.flat-menu nav ul.nav-main li.active > a {
    color: #666; }
  #header.flat-menu nav ul.nav-main li.active > a {
    color: #CD4945;
    background-color: transparent !important; }
  #header.flat-menu nav ul.nav-main li > a:hover,
  #header.flat-menu nav ul.nav-main li.dropdown:hover a {
    color: #FFF;
    background-color: #CD4945 !important; }
  #header nav.mega-menu ul.nav-main li.mega-menu-item ul.dropdown-menu {
    border-top-color: #CD4945; } }

blockquote.testimonial {
  background: #F77975; }

.testimonial-arrow-down {
  border-top-color: #F77975; }

.featured-box-secundary i.icon-featured {
  background-color: #CD4945; }

.featured-box-secundary h4 {
  color: #CD4945; }

.featured-box-secundary .box-content {
  border-top-color: #CD4945; }

.pricing-table .most-popular {
  border-color: #CD4945; }

.pricing-table .most-popular h3 {
  background-color: #CD4945; }

section.timeline .timeline-box.left:before,
section.timeline .timeline-box.right:before {
  background: #CD4945;
  box-shadow: 0 0 0 3px #ffffff, 0 0 0 6px #CD4945; }

ul.history li .featured-box .box-content {
  border-top-color: #CD4945; }

.recent-posts .date .month,
article.post .post-date .month {
  background-color: #CD4945; }

.recent-posts .date .day,
article.post .post-date .day {
  color: #CD4945; }

.slider .tp-bannertimer {
  background-color: #CD4945; }

.home-intro p em {
  color: #00aaff; }

.home-concept strong {
  color: #CD4945; }

.shop ul.products li.product .onsale {
  background-color: #CD4945;
  border-bottom-color: #006da3; }

.shop .star-rating span,
.shop .cart-totals tr.total .amount {
  color: #CD4945; }

#footer {
  background: #fff;
  color: #333;
  border-top: 1px solid #ccc;
  font-size: .9em;
  margin-top: 40px;
  padding: 40px 0 0;
  position: relative;
  clear: both; }

#footer .footer-ribbon {
  background: #CD4945; }

#footer .footer-ribbon:before {
  border-right-color: #005580;
  border-left-color: #005580; }

#footer.light h1,
#footer.light h2,
#footer.light h3,
#footer.light h4,
#footer.light a {
  color: #CD4945 !important; }

#footer.color {
  background: #CD4945;
  border-top-color: #CD4945; }

#footer.color .footer-ribbon {
  background: #006699; }

#footer.color .footer-ribbon:before {
  border-right-color: #00334d; }

#footer.color .footer-copyright {
  background: #933432;
  border-top-color: #933432; }

html.boxed .body {
  border-top-color: #CD4945; }

html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-left ul.nav-tabs li.active a:focus {
  border-right-color: #CD4945;
  border-left-color: transparent; }

html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:hover,
html[dir="rtl"] .tabs.tabs-vertical.tabs-right ul.nav-tabs li.active a:focus {
  border-right-color: transparent;
  border-left-color: #CD4945; }

.navbar-brand {
  padding: 0; }

.navbar-right {
  margin-top: 20px; }

.mdl-layout__header {
  height: 104px;
  overflow: hidden; }
  .mdl-layout__header.onRequest {
    height: 152px; }

.mdl-textfield--expandable {
  width: auto; }

.mdl-card > .mdl-button, mdl-card__title > .mdl-button {
  position: absolute;
  z-index: 99;
  top: 8px;
  right: 8px; }

.mdl-card > .mdl-menu__container {
  z-index: 99; }
